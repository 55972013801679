// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ApiPagingRequestDTO from '../../dto/resource/ApiPagingRequestDTO';

export default abstract class ApiPagingRequestModel extends BaseModel<ApiPagingRequestDTO> {
  /**
  * @type {int32}
  */
  get currentPage() { return this.dto.currentPage; }
  set currentPage(value) { this.dto.currentPage = value; }
  /**
  * @type {int32}
  */
  get pageSize() { return this.dto.pageSize; }
  set pageSize(value) { this.dto.pageSize = value; }
  /**
  */
  get includeCount() { return this.dto.includeCount; }
  set includeCount(value) { this.dto.includeCount = value; }
  /**
  */
  get noPaging() { return this.dto.noPaging; }
  set noPaging(value) { this.dto.noPaging = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key] = queries[key].toLowerCase(); });
    let value: any;
    value = Number.parseFloat(qs.currentpage);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.currentPage = value;
    value = Number.parseFloat(qs.pagesize);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.pageSize = value;
    value = qs.includeCount === 'true' ? true : qs.includecount;
    value = qs.includeCount === 'false' ? false : undefined;
    this.dto.includeCount = value;
    value = qs.noPaging === 'true' ? true : qs.nopaging;
    value = qs.noPaging === 'false' ? false : undefined;
    this.dto.noPaging = value;
  }
}
