import Vue from 'vue';
import appServices from '../api/App';
import appLogsServices from '../api/AppLogs';
import appSystemServices from '../api/AppSystem';
import authServices from '../api/Auth';
import contactIdMappingsServices from '../api/ContactIdMappings';
import documentsServices from '../api/Documents';
import fieldMappingServices from '../api/FieldMapping';
import individualProjectsServices from '../api/IndividualProjects';
import integrationCallsServices from '../api/IntegrationCalls';
import integrationTasksServices from '../api/IntegrationTasks';
import locationsServices from '../api/Locations';
import meServices from '../api/Me';
import processesServices from '../api/Processes';
import sequencenumbersServices from '../api/Sequencenumbers';
import tenantsServices from '../api/Tenants';
import usersServices from '../api/Users';

export default () => ({
  app: { ...appServices(Vue.$service) },
  appLogs: { ...appLogsServices(Vue.$service) },
  appSystem: { ...appSystemServices(Vue.$service) },
  auth: { ...authServices(Vue.$service) },
  contactIdMappings: { ...contactIdMappingsServices(Vue.$service) },
  documents: { ...documentsServices(Vue.$service) },
  fieldMapping: { ...fieldMappingServices(Vue.$service) },
  individualProjects: { ...individualProjectsServices(Vue.$service) },
  integrationCalls: { ...integrationCallsServices(Vue.$service) },
  integrationTasks: { ...integrationTasksServices(Vue.$service) },
  locations: { ...locationsServices(Vue.$service) },
  me: { ...meServices(Vue.$service) },
  processes: { ...processesServices(Vue.$service) },
  sequencenumbers: { ...sequencenumbersServices(Vue.$service) },
  tenants: { ...tenantsServices(Vue.$service) },
  users: { ...usersServices(Vue.$service) },
});
