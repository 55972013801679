// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import LocationCitySearchResourceDTO from '../../dto/resource/LocationCitySearchResourceDTO';

export default abstract class LocationCitySearchResourceModel extends BaseModel<LocationCitySearchResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  */
  get postalCode() { return this.dto.postalCode; }
  set postalCode(value) { this.dto.postalCode = value; }
  /**
  */
  get city() { return this.dto.city; }
  set city(value) { this.dto.city = value; }
  /**
  * @type {int64}
  */
  get stateId() { return this.dto.stateId; }
  set stateId(value) { this.dto.stateId = value; }
  /**
  */
  get stateCode() { return this.dto.stateCode; }
  set stateCode(value) { this.dto.stateCode = value; }
  /**
  */
  get stateName() { return this.dto.stateName; }
  set stateName(value) { this.dto.stateName = value; }
  /**
  * @type {int64}
  */
  get countryId() { return this.dto.countryId; }
  set countryId(value) { this.dto.countryId = value; }
  /**
  */
  get countryCode() { return this.dto.countryCode; }
  set countryCode(value) { this.dto.countryCode = value; }
  /**
  */
  get countryName() { return this.dto.countryName; }
  set countryName(value) { this.dto.countryName = value; }
  /**
  */
  get language_ISO2() { return this.dto.language_ISO2; }
  set language_ISO2(value) { this.dto.language_ISO2 = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key] = queries[key].toLowerCase(); });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.creatorid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = qs.creatorname;
    this.dto.created = qs.created;
    value = Number.parseFloat(qs.modifierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = qs.modifiername;
    this.dto.modified = qs.modified;
    this.dto.postalCode = qs.postalcode;
    this.dto.city = qs.city;
    value = Number.parseFloat(qs.stateid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.stateId = value;
    this.dto.stateCode = qs.statecode;
    this.dto.stateName = qs.statename;
    value = Number.parseFloat(qs.countryid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.countryId = value;
    this.dto.countryCode = qs.countrycode;
    this.dto.countryName = qs.countryname;
    this.dto.language_ISO2 = qs.language_iso2;
  }
}
