// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ContactIdMappingResourceDTO from '../../dto/resource/ContactIdMappingResourceDTO';

export default abstract class ContactIdMappingResourceModel extends BaseModel<ContactIdMappingResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  * @type {uuid}
  */
  get crmId() { return this.dto.crmId; }
  set crmId(value) { this.dto.crmId = value; }
  /**
  * @type {int64}
  */
  get foreignAppSystemId() { return this.dto.foreignAppSystemId; }
  set foreignAppSystemId(value) { this.dto.foreignAppSystemId = value; }
  /**
  */
  get foreignId() { return this.dto.foreignId; }
  set foreignId(value) { this.dto.foreignId = value; }
  /**
  * @type {int32}
  */
  get crmStateId() { return this.dto.crmStateId; }
  set crmStateId(value) { this.dto.crmStateId = value; }
  /**
  */
  get crmStateName() { return this.dto.crmStateName; }
  set crmStateName(value) { this.dto.crmStateName = value; }
  /**
  * @type {int32}
  */
  get mappingStateId() { return this.dto.mappingStateId; }
  set mappingStateId(value) { this.dto.mappingStateId = value; }
  /**
  */
  get mappingStateName() { return this.dto.mappingStateName; }
  set mappingStateName(value) { this.dto.mappingStateName = value; }
  /**
  */
  get mappingStateReason() { return this.dto.mappingStateReason; }
  set mappingStateReason(value) { this.dto.mappingStateReason = value; }
  /**
  */
  get currentContactData() { return this.dto.currentContactData; }
  set currentContactData(value) { this.dto.currentContactData = value; }
  /**
  */
  get previousContactData() { return this.dto.previousContactData; }
  set previousContactData(value) { this.dto.previousContactData = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key] = queries[key].toLowerCase(); });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.creatorid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = qs.creatorname;
    this.dto.created = qs.created;
    value = Number.parseFloat(qs.modifierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = qs.modifiername;
    this.dto.modified = qs.modified;
    this.dto.crmId = qs.crmid;
    value = Number.parseFloat(qs.foreignappsystemid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.foreignAppSystemId = value;
    this.dto.foreignId = qs.foreignid;
    value = Number.parseFloat(qs.crmstateid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.crmStateId = value;
    this.dto.crmStateName = qs.crmstatename;
    value = Number.parseFloat(qs.mappingstateid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.mappingStateId = value;
    this.dto.mappingStateName = qs.mappingstatename;
    this.dto.mappingStateReason = qs.mappingstatereason;
    this.dto.currentContactData = qs.currentcontactdata;
    this.dto.previousContactData = qs.previouscontactdata;
  }
}
