// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import AccessTokenResourceDTO from '../../dto/resource/AccessTokenResourceDTO';

export default abstract class AccessTokenResourceModel extends BaseModel<AccessTokenResourceDTO> {
  /**
  */
  get token() { return this.dto.token; }
  set token(value) { this.dto.token = value; }
  /**
  * @type {date-time}
  */
  get expiresOn() { return this.dto.expiresOn; }
  set expiresOn(value) { this.dto.expiresOn = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key] = queries[key].toLowerCase(); });
    let value: any;
    this.dto.token = qs.token;
    this.dto.expiresOn = qs.expireson;
  }
}
